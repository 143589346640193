import "./maternity.scss"

import React from "react"

import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import loadable from "@loadable/component"

// import BannerCall from "../components/BannerCall"
// import Choices from "../components/for-you/Choices"
// import Layout from "../components/Layout"
// import Shelf from "../components/Shelf"
// import Slogan from "../components/Slogan"
import Layout from "../components/Layout"
import {
  getAllLocales,
  getCurrentLanguagePage,
  getGatsbyImage,
  getBackgroundImage,
} from "../plugins/common"

const BannerCall = loadable(() => import("../components/BannerCall"))
const Choices = loadable(() => import("../components/for-you/Choices"))
const Shelf = loadable(() => import("../components/Shelf"))
const Slogan = loadable(() => import("../components/Slogan"))

const maternityHeaderRightEmpty =
  "../images/maternity/maternityHeaderRightEmpty.webp"
const maternityHeaderRightFull =
  "../images/maternity/maternityHeaderRightFull.webp"
const maternityShelfLeftCopper =
  "../images/maternity/maternityShelfLeftCopper.webp"
const maternityShelfRightEmpty =
  "../images/maternity/maternityShelfRightEmpty.webp"
const maternityShelfRightFull =
  "../images/maternity/maternityShelfRightFull.webp"
const maternityBannerRightEmpty =
  "../images/maternity/maternityBannerRightEmpty.webp"
const maternityBannerRightFull =
  "../images/maternity/maternityBannerRightFull.webp"
const maternityChoicesLeftFull =
  "../images/maternity/maternityChoicesLeftFull.webp"

const MaternityPage = ({ pageContext, location }) => {
  const { node: data, general, form } = pageContext
  const headerImage = getGatsbyImage(data.headerImage.image[0])
  const bannerImage = getGatsbyImage(data.banner.image[0])
  const aleaToken = general.aleaToken
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const ogImage = getBackgroundImage(data.headerImage.image[0])
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }
  // console.log(data)

  return (
    <div className="maternity">
      <Layout
        props={general}
        meta={data.meta}
        localizations={getAllLocales(data.localizations, data.locale)}
        location={location}
        lang={lang}
        ogImage={ogImage}
        hideNewsletter={true}
        token={aleaToken}
        footerForm={enableFooterForm ? footerForm : null}
      >
        <div className="overflow-hidden">
          <Slogan props={data.headerImage}>
            <div id="right" className="maternityHeader position-relative">
              <div className="imageZoom d-unset">
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="image imageAni"
                  image={headerImage}
                  alt="alea"
                />
              </div>
              <div className="">
                <StaticImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="rightEmpty position-absolute"
                  src={maternityHeaderRightEmpty}
                  alt=""
                  layout="constrained"
                />
                <StaticImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="rightFull position-absolute"
                  src={maternityHeaderRightFull}
                  alt=""
                  layout="constrained"
                />
              </div>
            </div>
          </Slogan>
          <div className="maternityShelf">
            <div className=" position-relative">
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="leftCopper position-absolute"
                src={maternityShelfLeftCopper}
                alt=""
                layout="constrained"
              />
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="rightEmpty position-absolute"
                src={maternityShelfRightEmpty}
                alt=""
                layout="constrained"
              />
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="rightFull position-absolute"
                src={maternityShelfRightFull}
                alt=""
                layout="constrained"
              />

              <Shelf
                title=""
                list={data.description}
                startFrom="left"
                mobileStartFrom="text"
              />
            </div>
          </div>
          <BannerCall props={data.banner} bgColor="bg-mint">
            <div className="maternityBanner position-relative">
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="rightEmpty position-absolute"
                src={maternityBannerRightEmpty}
                alt=""
                layout="constrained"
              />
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                className="rightFull position-absolute"
                src={maternityBannerRightFull}
                alt=""
                layout="constrained"
              />

              <div className="imageZoom d-unset">
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className="image imageAni position-absolute"
                  image={bannerImage}
                  alt="alea"
                />
              </div>
            </div>
          </BannerCall>

          <div className="maternityChoices position-relative">
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className="leftFull position-absolute"
              src={maternityChoicesLeftFull}
              alt=""
              layout="constrained"
            />
            <Choices
              props={data.products}
              dashColor="bg-copper"
              general={pageContext.general}
              lang={lang}
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}
export default MaternityPage
